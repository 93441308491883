
import { formatCurrencyCompactLong } from "@/plugins/filters";
import Vue from "vue";
export default Vue.extend({
  name: "TenantCondoViewer",
  props: {
    rate: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  methods: {
    formatCurrency(value: any) {
      if ((value && !isNaN(value)) || value === 0) {
        return formatCurrencyCompactLong(value);
      } else {
        return "N/A";
      }
    }
  },
  computed: {
    numberOfPaymentsLeft() {
      const { rate } = this;
      if (rate.res_NumPay && rate.res_NumPay !== "0") {
        return rate.res_NumPay;
      }
      return "";
    }
  }
});
